import { css } from '@emotion/react'

const style = {
  wrapper: centered => css`
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem;

    ${centered &&
    `
      justify-content: center;
    `}

    @media (min-width: 768px) {
      margin: -0.5rem;
    }
  `,

  box: css`
    padding: 0.25rem;

    @media (min-width: 768px) {
      padding: 0.5rem;
    }
  `,

  card: css`
    width: auto;
  `,

  sliderWrapper: css`
    position: relative;
    margin: 0 -0.5rem;
    padding: 0 0.5rem;

    @media (min-width: 768px) {
      margin: 0 -0.75rem;
      padding: 0 0.75rem;
    }
  `,
}

export default style
