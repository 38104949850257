import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import Footer from 'swp-components/lib/components/footer'
import Section from 'swp-components/lib/components/section'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import InternetPackage from '@/components/internet-package'
import InternetPackageCard from '@/components/internet-package-card'
import Newrelic from '@/components/newrelic-agent'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import ShortcutMenu from '@/components/shortcut-menu'
import TopBanner from '@/components/top-banner'
import useTrueOnlinePageDataLayer from '@/hooks/data-layers/use-true-online-page'
import strapi from '@/strapi'

const TrueOnlinePage = ({ serverData }) => {
  let [
    seo,
    headerScript,
    headerPage,
    brandHeaders,
    productServices,
    topBanners,
    shortcutMenus,
    tolPackageTypes,
    tolPackages,
    tolExtraServices,
    footerHeaders,
    chatShop,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let { handleMessage, createScopeProps } = useTrueOnlinePageDataLayer()

  //sort by position
  tolPackages.sort((a, b) => a.position - b.position)
  tolPackages = tolPackages.filter(pack => pack.packageOption)
  tolPackages.map(pack => {
    tolPackageTypes.map(type => {
      if (pack.packageOption.tolPackageType) {
        if (type.id === pack.packageOption.tolPackageType.id) {
          pack['id'] = `${type.id}:${pack.id}`
          type['tolPackages'] = type['tolPackages'] ?? []
          type['tolPackages'].push(pack)
        }
      }
    })
  })

  tolPackageTypes = tolPackageTypes.filter(type => type.tolPackages)

  let extraServiceHeader = headerPage.trueonlinePage.extraServiceHeader ?? ''
  let extraService = tolExtraServices.map(item => {
    return {
      id: item.id,
      name: item.name,
      detailLink: item.link,
      detail: item.detail,
      image: item.image,
      buttonName: '',
    }
  })

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={seo.tolSeo} script={headerScript.script} />
        <DataLayerScope {...createScopeProps('Header')}>
          <Header items={brandHeaders} services={productServices} />
        </DataLayerScope>
        {topBanners.length > 0 && (
          <DataLayerScope {...createScopeProps('TopBanner', { topBanners })}>
            <TopBanner banners={topBanners} />
          </DataLayerScope>
        )}
        {shortcutMenus.length > 0 && (
          <DataLayerScope {...createScopeProps('ShortcutMenu')}>
            <Section.Background className="py-4" color="light-blue">
              <ShortcutMenu color="blue" items={shortcutMenus} />
            </Section.Background>
          </DataLayerScope>
        )}
        {tolPackageTypes.map((type, index) => {
          return (
            <div className="mt-5" id="packages" key={index}>
              <>
                <SectionHeading tag="h1">{type.name}</SectionHeading>
                <SectionHeading
                  className="mt-1 mb-4"
                  level="2"
                  variant="underline"
                >
                  {type.detail}
                </SectionHeading>
              </>

              <DataLayerScope
                {...createScopeProps('InternetPackage', {
                  internetPackages: [{ packages: type.tolPackages }],
                  header: type.name,
                })}
              >
                <InternetPackage
                  packages={[{ packages: type.tolPackages }]}
                  preferWidth={320}
                />
              </DataLayerScope>
            </div>
          )
        })}

        <div className="my-5">
          <>
            <SectionHeading tag="h1">{extraServiceHeader.name}</SectionHeading>
            <SectionHeading className="mt-1 mb-4" level="2" variant="underline">
              {extraServiceHeader.detail}
            </SectionHeading>
          </>
          <DataLayerScope
            {...createScopeProps('InternetPackage', {
              header: extraServiceHeader,
            })}
          >
            <InternetPackageCard packages={extraService} />
          </DataLayerScope>
        </div>
        {footerHeaders && <Footer className="pb-4" items={footerHeaders} />}
        {chatShop && (
          <ChatShop
            content={chatShop}
            mariPlatform={ serverData.locale == 'th' ? "mari_tol" : "trueonline" }
            mariConfig={mariConfig}
          />
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TrueOnlinePage

export const getServerData = async ({ pageContext }) => {
  let contents = await strapi.getContents(
    [
      'seo',
      'header-script',
      'header-page',
      'brand-headers',
      'product-services',
      'tol-top-banners',
      'tol-shortcut-menus',
      'tol-package-types',
      'tol-packages',
      'tol-extra-services',
      'footer-headers',
      'chat-and-shop',
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  return {
    props: { contents, locale: pageContext.locale },
  }
}
