import { useEffect, useRef, useState } from 'react'

const useColumns = breakpoints => {
  let [columns, setColumns] = useState(0)

  let handler = useRef()

  handler.current = () => {
    let newColumns = 1

    for (let breakpoint of breakpoints) {
      if (matchMedia(`(min-width: ${breakpoint.value})`).matches) {
        newColumns = breakpoint.columns
      }
    }

    if (newColumns !== columns) {
      setColumns(newColumns)
    }
  }

  useEffect(() => {
    handler.current()
  }, [breakpoints])

  useEffect(() => {
    let listener = () => {
      handler.current()
    }

    listener()
    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return { columns }
}

export default useColumns
