import { breakpoints } from 'swp-components/lib/assets/config/style'
import { Container } from 'swp-components/lib/components/bootstrap'
import { PackageTypeCard } from 'swp-components/lib/components/internet-package'

import useColumns from '@/hooks/use-columns'
import generateSliderOptions from '@/util/generate-slider-options'

import Slider from '../slider'
import style from './style'

const InternetPackageCard = ({ packages, ...props }) => {
  let { columns } = useColumns([
    { value: breakpoints.sm, columns: 2 },
    { value: breakpoints.lg, columns: 3 },
    { value: breakpoints.xl, columns: 5 },
  ])

  let sliderOptions = generateSliderOptions(280)

  return (
    <Container {...props}>
      <div css={style.sliderWrapper}>
        <Slider options={sliderOptions}>
          {packages.map(pack => (
            <div
              key={pack.id}
              css={style.box}
              style={{ width: `${100 / columns}%` }}
            >
              <PackageTypeCard css={style.card} packageType={pack} />
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  )
}

export default InternetPackageCard
